import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        minHeight: 200,
        boxShadow: 'unset',
        backgroundColor: 'unset'
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
        paddingTop: theme.spacing(2)
    },
    cover: {
        height: '80%',
        [theme.breakpoints.up('xs')]: {
            height: '280px'
        },
        [theme.breakpoints.up('md')]: {
            // height: '345px',
            width: '540px',
            height: '100%',
        },
        [theme.breakpoints.up('xl')]: {
            height: '100%',
        },
    },
    title: {
        textAlign: 'left',
        [theme.breakpoints.up('xs')]: {
            paddingLeft: theme.spacing(0),
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(12),
        },
    },
    leftTitle: {
        textAlign: 'left',
    },
    description: {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('xs')]: {
            paddingLeft: theme.spacing(0),
            width: 'auto',
            textAlign: 'left'
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(12),
            width: 'auto',
            textAlign: 'left'
        },
    },
    leftDescription: {
        marginBottom: theme.spacing(5),
        [theme.breakpoints.up('xs')]: {
            width: 'auto',
            textAlign: 'left'
        },
        [theme.breakpoints.up('md')]: {
            paddingRight: theme.spacing(12),
            width: 'auto',
            textAlign: 'left'
        },
    },
    button: {
        float: 'left',
        height: '43px',
        fontSize: 12,
        [theme.breakpoints.up('xs')]: {
            marginLeft: theme.spacing(0),
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(12),
        },
        '&:hover': {
            fontWeight: 'bolder',
        }
    },
    leftButton: {
        float: 'left',
        height: '43px',
        fontSize: 12,
        '&:hover': {
            fontWeight: 'bolder',
        }
    },
    noDecoration: {
        textDecoration: 'none'
    }
}));

export default function ServiceCard({ ...props }) {
    const classes = useStyles();
    // If trigger MobileView
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Card className={classes.root}>
            <Grid container spacing={2}>
                {
                    props.isRight ?
                        isMobileView ?
                            null
                            :
                            <Grid item xs={12} md={5}>
                                <CardMedia
                                    className={classes.cover}
                                    image={props.img}
                                    title={props.title}
                                />
                            </Grid>
                        :
                        <Grid item xs={12} md={5}>
                            <CardMedia
                                className={classes.cover}
                                image={props.img}
                                title={props.title}
                            />
                        </Grid>
                }
                <Grid item xs={12} md={7}>
                    <div className={classes.details}>
                        <CardContent className={classes.content}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" className={props.isRight ? classes.leftTitle : classes.title} gutterBottom>
                                        <b>{props.title}</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="subtitle1" color="textSecondary" className={props.isRight ? classes.leftDescription : classes.description}>
                                        {props.description}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Link to={props.link} className={classes.noDecoration}>
                                        <Button color="primary" className={props.isRight ? classes.leftButton : classes.button}>
                                            <b style={{ fontSize: 14, opacity: 0.6, letterSpacing: 0.5 }}>Learn More</b>
                                        </Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </div>
                </Grid>
                {
                    props.isRight ?
                        isMobileView ?
                            <Grid item xs={12} md={5}>
                                <CardMedia
                                    className={classes.cover}
                                    image={props.img}
                                    title={props.title}
                                />
                            </Grid>
                            : null
                        :
                        null
                }
            </Grid>
        </Card>
    );
}