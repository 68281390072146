
import React, { Fragment, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ServicesCard from '../../components/card/Service';
import HeaderImg from '../../assets/img/header/header.png';
import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles((theme) => ({
    heroContent: {
        minHeight: '40vh',
        backgroundImage: `url(${HeaderImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative'
    },
    centeredText: {
        [theme.breakpoints.up('xs')]: {
            paddingTop: theme.spacing(16),
            paddingBottom: theme.spacing(5)
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(18),
            paddingBottom: theme.spacing(5)
        },
    },
    title: {
        fontWeight: 'bold',
        color: '#fff'
    },
    subTitle: {
        lineHeight: 2,
        opacity: 0.8,
        color: '#fff',
        marginTop: 30
    },
    cardGrid: {
        [theme.breakpoints.up('xs')]: {
            paddingTop: theme.spacing(0),
            paddingBottom: theme.spacing(3),
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(10),
            paddingBottom: theme.spacing(20),
        },
    },
}));

export default function Services() {
    const classes = useStyles();
    // If trigger MobileView
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => { window.scrollTo(0, 0); }, [])

    const serviceData = [
        {
            id: 1,
            isRight: false,
            title: 'Manned Guarding',
            img: 'https://res.cloudinary.com/alkautsars/image/upload/v1614154513/isc-gulf/service/Img_security_1_ceeycb.png',
            description: 'Our guards are usually former police or military personnel and receive training in all the latest industrial security, and safety techniques.',
            link: '/services/mannedGuarding',
            transitionDelay: '0ms',
        },
        {
            id: 2,
            isRight: true,
            title: 'Consultancy and Design',
            img: 'https://res.cloudinary.com/alkautsars/image/upload/v1614154514/isc-gulf/service/Img_security_2_shytqh.png',
            description: 'As a company that prides itself on the quality and breadth of experience that its employees posses, we are able to offer a comprehensive consultancy service.',
            link: '/services/consultancy',
            transitionDelay: '300ms',
        },
        {
            id: 3,
            isRight: false,
            title: 'Security Systems',
            img: 'https://res.cloudinary.com/alkautsars/image/upload/v1614154516/isc-gulf/service/Img_security_3_gmdgfc.png',
            description: 'We can provide you equipment services such as installation service, preventive maintenance service, and corrective maintenance service.',
            link: '/services/securitySystems',
            transitionDelay: '600ms',
        },
    ]

    return (
        <Fragment>
            <div className={classes.heroContent}>
                <Container maxWidth="lg" className={classes.centeredText}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Container maxWidth="md">
                                <Typography component="h1" variant={isMobileView ? "h3" : "h4"} align="center" className={classes.title}>
                                    Our <b style={{ color: '#FFD400' }}>Services</b>.
                                </Typography>
                                <Typography variant={isMobileView ? "body1" : "subtitle2"} align="center" color="textSecondary" className={classes.subTitle}>
                                    List of our services
                                </Typography>
                            </Container>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Container className={classes.cardGrid} maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} md={12} style={{ paddingTop: 40 }}>
                        <Grid container spacing={4}>
                            {serviceData.map(x => (
                                <Zoom in={true} key={x.id} style={{ transitionDelay: x.transitionDelay }}>
                                    <Grid item xs={12} style={{ marginTop: 20 }}>
                                        <ServicesCard isRight={x.isRight} title={x.title} img={x.img} description={x.description} link={x.link} sub={x.sub} />
                                    </Grid>
                                </Zoom>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Fragment >
    );
}
