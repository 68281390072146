import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
    root: {
        // maxHeight: '100vh',
        boxShadow: 'unset',
        borderRadius: 18
    },
    text: {
        lineHeight: 2.3,
        [theme.breakpoints.up('xs')]: {
            fontSize: 14,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 16,
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: 16,
        },
    },
}));

export default function MannedGuard() {
    const classes = useStyles();

    useEffect(() => { window.scrollTo(0, 0); }, [])

    return (
        <Card className={classes.root}>
            <CardContent>
                <Container maxWidth="md">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} style={{ paddingTop: 40 }}>
                            <Typography variant="h6" align="left" color="primary" gutterBottom>
                                <b>Installation Service</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} style={{ paddingTop: 10 }}>
                            <Typography className={classes.text} align="left" gutterBottom style={{ marginBottom: 10 }}>
                                <b>Fully qualified engineers</b> are available to install CCTV, Access Control and Intruder Alarm systems to the precise requirements of the client. Based on the scope of works each client is allocated a project manager and a dedicated engineer until the completion of works. Daily or weekly progress reports can be provided if so required.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} style={{ paddingTop: 40 }}>
                            <Typography variant="h6" align="left" color="primary" gutterBottom>
                                <b>Preventative Maintenance Service</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} style={{ paddingTop: 10 }}>
                            <Typography className={classes.text} align="left" gutterBottom style={{ marginBottom: 10 }}>
                                This service is intended to <b>reduce the number of system faults</b> that may occur over a given period and extend the operational life of the system.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} style={{ paddingTop: 40 }}>
                            <Typography variant="h6" align="left" color="primary" gutterBottom>
                                <b>Corrective Maintenance Service</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} style={{ paddingTop: 10 }}>
                            <Typography className={classes.text} align="left" gutterBottom style={{ marginBottom: 10 }}>
                                Utilizing fully trained mobile engineers we are able to provide this service through our central coordination centre and callout management system. This service can be available <b>24 hours a day</b>, <b>365 days a year</b> with client specified response times based on fault categories. All reports and administration requirements can be met and customized to each clients requirements.
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </CardContent >
        </Card >
    );
}