import React, { Fragment, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Detail from '../components/card/Detail';

const useStyles = makeStyles((theme) => ({
    heroContent: {
        padding: theme.spacing(10, 0, 15),
        minHeight: '70vh',
        backgroundImage: `url(${"https://res.cloudinary.com/alkautsars/image/upload/v1614154263/isc-gulf/Header/img_about_us_crzr32.png"})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    titleWrapper: {
        paddingTop: theme.spacing(12),
    },
    cardGrid: {
        marginTop: -350,
        paddingBottom: theme.spacing(8),
        [theme.breakpoints.up('xs')]: {
            paddingTop: theme.spacing(13),
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(20)
        },
        [theme.breakpoints.up('xl')]: {
            paddingTop: theme.spacing(8)
        },
    },
}));


export default function LearnMore() {
    const classes = useStyles();

    useEffect(() => { window.scrollTo(0, 0); }, [])

    return (
        <Fragment>
            <CssBaseline />
            <main>
                <div className={classes.heroContent}>
                    <Container maxWidth="lg" className={classes.titleWrapper}>
                        <Typography variant="h3" align="center" gutterBottom style={{ color: '#fff' }}>
                            <b>Learn More</b>
                        </Typography>
                    </Container>
                </div>
                <Container className={classes.cardGrid} maxWidth="md">
                    <Detail />
                </Container>
            </main>
        </Fragment>
    );
}