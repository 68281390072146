import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PulseLoader from "react-spinners/PulseLoader";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-50px',
        marginLeft: '-50px',
        width: '100px',
        height: '100px'
    },
}));

export default function Loader({ ...props }) {
    const classes = useStyles();
    const { isOpen } = props

    return (
        <div className={classes.wrapper}>
            <PulseLoader color={'#01056D'} loading={isOpen} size={20} margin={2} />
        </div>
    );
}