import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';
import Loader from '../Loader';
import Snackbar from '../Snackbar';

export default function AlertDialog({ ...props }) {
    const { isOpen, isClose } = props
    const [openLoader, setOpenLoader] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [emailError, setIsEmailError] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false);
    const [department, setDepartment] = useState('');
    const [personalData, setPersonalData] = useState({
        full_name: '',
        address: '',
        email_address: '',
        phone_number: '',
    });
    const [problemReport, setProblemReport] = useState({
        problem_title: '',
        problem_description: ''
    });


    function validateEmail(email) {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    // 
    const handleChangeFullName = e => {
        setPersonalData({ ...personalData, full_name: e.target.value })
    };
    const handleChangeEmail = e => {
        setPersonalData({ ...personalData, email_address: e.target.value })
        validateEmail(personalData.email_address) ?
            setIsEmailError(false) : setIsEmailError(true)
    };
    const handleChangeMobilePhone = e => {
        setPersonalData({ ...personalData, phone_number: e.target.value })
    };
    const handleChangeAddress = e => {
        setPersonalData({ ...personalData, address: e.target.value })
    };
    const handleChangeDepartment = (event) => {
        setDepartment(event.target.value);
    };
    const handleChangeProblemTitle = e => {
        setProblemReport({ ...problemReport, problem_title: e.target.value })
    };
    const handleChangeProblemDescription = e => {
        setProblemReport({ ...problemReport, problem_description: e.target.value })
    };

    const handleSubmitReport = e => {
        e.preventDefault();
        setOpenLoader(true)
        axios.post(process.env.REACT_APP_BASE_URL + 'api/ticket/store', { ...personalData, department, ...problemReport }, {
            headers: {
                APP_KEY: 'isc gulf'
            }
        })
            .then(res => {
                setOpenLoader(false)
                setOpenSnackbar(true)
                setIsSuccess(true)
                setPersonalData({
                    full_name: '',
                    address: '',
                    email_address: '',
                    phone_number: '',
                })
                setProblemReport({
                    problem_title: '',
                    problem_description: ''
                })
                setDepartment('')
            })
            .catch(err => {
                setOpenLoader(false)
                setOpenSnackbar(true)
                setIsSuccess(false)
            })
    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false)
    }

    return (
        <Dialog
            open={isOpen}
            onClose={isClose}
        >
            <Loader isOpen={openLoader} />
            <Snackbar isOpen={openSnackbar} isClose={handleCloseSnackbar} isSuccess={isSuccess} />
            <DialogTitle><b>{"Service Inquiry"}</b></DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1" gutterBottom>
                    <b>This service applies for ISC Group Gulf costumer and membership.</b>
                </Typography>
                <Typography variant="subtitle1">
                    For further information, please call (+974) 44432751
                </Typography>
                <Typography variant="subtitle1" style={{ paddingTop: 20 }} gutterBottom>
                    <b>General Information</b>
                </Typography>
                <Typography variant="subtitle1">
                    Personal Data Report
                </Typography>
                <Grid container spacing={2} style={{ paddingTop: 20 }}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            onChange={handleChangeFullName}
                            value={personalData.full_name}
                            label="Full Name"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            onChange={handleChangeEmail}
                            value={personalData.email_address}
                            label="Email"
                            error={emailError}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            onChange={handleChangeMobilePhone}
                            value={personalData.phone_number}
                            label="Mobile Phone"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            onChange={handleChangeAddress}
                            value={personalData.address}
                            label="Address"
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Typography variant="subtitle1" style={{ paddingTop: 20 }} gutterBottom>
                    <b>Inquiry Details</b>
                </Typography>
                <Grid container spacing={2} style={{ paddingTop: 20 }}>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>Department</InputLabel>
                            <Select
                                value={department}
                                onChange={handleChangeDepartment}
                                label="Department"
                            >
                                <MenuItem value="" disabled>
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={"Technical"}>Technical</MenuItem>
                                <MenuItem value={"Security"}>Security</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            onChange={handleChangeProblemTitle}
                            value={problemReport.problem_title}
                            label="Title"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            onChange={handleChangeProblemDescription}
                            value={problemReport.problem_description}
                            label="Description"
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmitReport} color="primary" disabled={!personalData.full_name || !personalData.address || !personalData.email_address || emailError || !personalData.phone_number || !problemReport.problem_title || !problemReport.problem_description || !department} autoFocus>
                    Send
                </Button>
                <Button onClick={isClose} color="primary" autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}