import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { NavHashLink } from 'react-router-hash-link';
import AnnivLogo from '../assets/img/header/anniv.png'

const useStyles = makeStyles((theme) => ({
    icon: {
        marginLeft: theme.spacing(2)
    },
    appBarRoot: {
        background: '#fff',
        height: 'unset',
        borderBottom: '3px solid #01056D',
        [theme.breakpoints.up('xs')]: {
            padding: theme.spacing(0, 0, 0, 0),
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0, 10, 0, 10),
        },
    },
    option: {
        fontSize: 16,
        textDecoration: 'unset',
        color: theme.palette.primary.main,
        textTransform: 'none',
        '&:hover': {
            color: theme.palette.secondary.main,
            fontSize: 18,
            fontWeight: 'bold'
        }
    },
    fullWidth: {
        width: '100%',
        justifyContent: 'right',
        marginLeft: theme.spacing(2)
    },
    img: {
        [theme.breakpoints.up('xs')]: {
            maxHeight: '70px'
        },
        [theme.breakpoints.up('md')]: {
            maxHeight: '70px'
        },
    },
    logoWrapper: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    mobileLogoWrapper: {
        display: 'contents'
    },
    textLeft: {
        textAlign: 'left'
    },
    centeredToolbar: {
        alignSelf: 'center',
        width: '100%',
    },
}));

const navigationList = [
    {
        id: 1,
        to: '/#home',
        name: 'Home',
    },
    {
        id: 2,
        to: '/#aboutUs',
        name: 'About Us',
    },
    {
        id: 3,
        to: '/services',
        name: 'Services',
    },
    {
        id: 4,
        to: '/partners',
        name: 'Partners',
    },
]

function MobileMenu() {
    const classes = useStyles();

    const ITEM_HEIGHT = 48;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                        boxShadow: 'none',
                        marginTop: 50,
                        background: '#fff',
                    },
                }}
            >
                {navigationList.map((x) => (
                    <Button key={x.id} color="inherit" className={classes.fullWidth}>
                        <a href={x.to} className={classes.option} style={{ fontSize: 15 }}>
                            {x.name}
                        </a>
                    </Button>
                ))}
            </Menu>
        </div>
    );
}

export default function NavigationBar() {
    const classes = useStyles();
    // If trigger MobileView
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <AppBar position="fixed" className={classes.appBarRoot}>
            <Toolbar disableGutters={true} className={classes.centeredToolbar}>
                <div className={classes.logoWrapper}>
                    <a href="/" className={classes.mobileLogoWrapper}>
                        <img src={"https://res.cloudinary.com/alkautsars/image/upload/v1614155310/isc-gulf/Header/image_1_kf5ha2.png"} alt="Logo" height="70" width="70" className={classes.icon} />
                        <img src={AnnivLogo} alt="Logo" height="53" width="140" className={classes.icon} />
                    </a>
                </div>
                {
                    isMobileView ?
                        navigationList.map((x) => (
                            <Button key={x.id} color="inherit" style={{ backgroundColor: 'transparent', marginLeft: 10 }}>
                                <NavHashLink smooth activeStyle={{ fontSize: 20, fontWeight: 'bold' }} to={x.to} className={classes.option}>
                                    {x.name}
                                </NavHashLink>
                            </Button>
                        ))
                        :
                        <MobileMenu />
                }
            </Toolbar>
        </AppBar>
    );
}