
import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AboutUs from './Index/AboutUs';
import Partners from './Index/Partners';
import HeaderImg from '../assets/img/header/header.png';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    minHeight: '55vh',
    backgroundImage: `url(${HeaderImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative'
  },
  centeredText: {
    [theme.breakpoints.up('xs')]: {
      paddingTop: theme.spacing(16),
      paddingBottom: theme.spacing(5)
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(18),
      paddingBottom: theme.spacing(5)
    },
  },
  title: {
    lineHeight: 1.3,
    fontWeight: 'bold',
    color: '#fff'
  },
  subTitle: {
    lineHeight: 2,
    opacity: 0.8,
    color: '#fff',
    marginTop: 30,
    fontStyle: 'italic'
  }

}));

export default function Index() {
  const classes = useStyles();
  // If trigger MobileView
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Fragment>
      <div className={classes.heroContent} id="home">
        <Container maxWidth="lg" className={classes.centeredText}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Container maxWidth="md">
                <Typography component="h1" variant={isMobileView ? "h3" : "h4"} align="center" className={classes.title}>
                  Providing the <b style={{ color: '#FFD400' }}>latest innovation solutions</b> and <b style={{ color: '#FFD400' }}>development</b> to our clients.
                </Typography>
                <Typography variant={isMobileView ? "body1" : "subtitle2"} align="center" color="textSecondary" className={classes.subTitle}>
                  "We have organized our company to focus our resources on this commitment. ISC Group can provide them with complete and highly professional Security Solution and Services."
              </Typography>
              </Container>
            </Grid>
          </Grid>
        </Container>
      </div>
      <AboutUs />
      <Partners />
    </Fragment >
  );
}
