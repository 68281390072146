// import React, { useState } from 'react';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Shield from '../../assets/img/aboutUs/shield.png'

const useStyles = makeStyles((theme) => ({
    bigCardRoot: {
        minWidth: 275,
        minHeight: 280,
        backgroundImage: `url(${Shield})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right -520px top -205px',
        backgroundSize: 'cover',
        boxShadow: '11px -2px 16px 3px rgba(232,232,232,0.7)',
        [theme.breakpoints.up('xs')]: {
            backgroundPosition: 'right -80px bottom -100px',
            backgroundSize: 'contain',
        },
        [theme.breakpoints.up('md')]: {
            backgroundPosition: 'right -500px top -205px',
            backgroundSize: 'cover',
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            height: '100%',
            '& $hiddenText': {
                visibility: 'visible',
                color: '#fff',
            },
            '& $title': {
                display: 'none',
                visibility: 'hidden',
            }
        }
    },
    smallCardRoot: {
        minWidth: 275,
        height: 280,
        backgroundImage: `url(${"https://res.cloudinary.com/alkautsars/image/upload/v1614168178/isc-gulf/About%20us/img_about_nziwdb_1_pvmft4.png"})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right -100px bottom -100px',
        backgroundSize: 'contain',
        boxShadow: '11px -2px 16px 3px rgba(232,232,232,0.7)',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            height: '100%',
            '& $hiddenText': {
                visibility: 'visible',
                color: '#fff',
            },
            '& $title': {
                display: 'none',
                visibility: 'hidden',
            }
        }
    },
    hiddenText: {
        visibility: 'hidden',
    },
    title: {
        visibility: 'visible',
        // marginTop: 20
    }
}));

export default function AboutCard({ ...props }) {
    const classes = useStyles();

    return (
        <Card
            className={props.isBig ? classes.bigCardRoot : classes.smallCardRoot}
            style={{ backgroundImage: `url(${props.img})` }}
        >
            <CardContent>
                <Container maxWidth="md">
                    <Typography variant="h4" align="left" className={classes.title}>
                        <b>{props.title}</b>
                    </Typography>
                    {
                        props.isMission ?
                            <div>
                                <Typography variant="body1" className={classes.hiddenText}>
                                    <b>Objective:</b> {props.description}
                                </Typography>
                                <br />
                                <Typography variant="body1" className={classes.hiddenText}>
                                    <b>Mission:</b> {props.description2}
                                </Typography>
                            </div>
                            :
                            <div>
                                <Typography variant="subtitle1" className={classes.hiddenText}>
                                    {props.description}
                                </Typography>
                            </div>
                    }
                </Container>
            </CardContent>
        </Card>
    );
}