import React, { Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Detail from '../../../components/card/Services/Consultancy';

const useStyles = makeStyles((theme) => ({
    heroContent: {
        minHeight: '70vh',
        backgroundImage: `url(${"https://res.cloudinary.com/alkautsars/image/upload/v1614154249/isc-gulf/Header/Img_security_12_tgx59k.png"})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        [theme.breakpoints.up('xs')]: {
            padding: theme.spacing(6, 0, 15),
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(10, 0, 15),
        },
        [theme.breakpoints.up('xl')]: {
            paddingTop: theme.spacing(8)
        },
    },
    titleWrapper: {
        paddingTop: theme.spacing(12),
    },
    cardGrid: {
        marginTop: -350,
        paddingBottom: theme.spacing(8),
        [theme.breakpoints.up('xs')]: {
            paddingTop: theme.spacing(32),
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(20)
        },
        [theme.breakpoints.up('xl')]: {
            paddingTop: theme.spacing(8)
        },
    },
}));


export default function Consultancy() {
    const classes = useStyles();

    return (
        <Fragment>
            <CssBaseline />
            <main>
                <div className={classes.heroContent}>
                    <Container maxWidth="lg" className={classes.titleWrapper}>
                        <Typography variant="h3" align="center" gutterBottom style={{ color: '#fff' }}>
                            <b>Security Consultancy and Risk Assetand</b>
                        </Typography>
                    </Container>
                </div>
                <Container className={classes.cardGrid} maxWidth="md">
                    <Detail />
                </Container>
            </main>
        </Fragment>
    );
}