import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles({
    root: {
        // maxHeight: '100vh',
        boxShadow: 'unset',
        borderRadius: 18
    },
    title: {
        fontSize: 14,
        lineHeight: 2
    },
    subTitle: {
        fontSize: 16,
        paddingTop: 10
    },
    pos: {
        marginBottom: 12,
    },
    descriptionText: {
        paddingTop: 10,
        lineHeight: 1.9
    },
    button: {
        marginTop: 30,
        marginBottom: 20,
        height: 45,
        '&:hover': {
            backgroundColor: '#181545'
        }
    }
});

export default function SimpleCard() {
    const classes = useStyles();

    useEffect(() => { window.scrollTo(0, 0); }, [])

    return (
        <Card className={classes.root}>
            <CardContent>
                <Container maxWidth="md">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} style={{ paddingTop: 40 }}>
                            <Typography variant="h6" align="left" color="primary" gutterBottom>
                                <b>Coorporate Information</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} style={{ paddingTop: 10, paddingBottom: 40 }}>
                            <Typography className={classes.title} align="left" gutterBottom>
                                Founded in 1999, International Security Consultancy - ISC Group Gulf W.L.L., offers a comprehensive manned guarding, security consultancy, design and installation service all with preventative maintenance support to major clients in Doha. We approach these important aspects of security in a structured and procedural manner, which allows us to provide one of the most organised and professional services currently available in the state of Qatar.
                            </Typography>
                            <Typography className={classes.title} align="left" gutterBottom>
                                ISC originally specialised in manned guarding and installation of security systems and the support of technical teams from internationally renowned selected suppliers, we progressed to the design and installation of fully integrated systems.
                            </Typography>
                            <Typography className={classes.title} align="left" gutterBottom>
                                ISC have built strong alliances with security and systems manufacturers and suppliers throughout the Arabian Gulf, Europe and the USA, placing us in the enviable position of being able to provide the very latest and most cost-effective equipment for our client's needs. All equipment is supplied with original supplier licences. Staying at the forefront of technology we are able to offer state of the art advice on international security issues and provide comprehensive solutions to security problems.
                            </Typography>
                            <Typography className={classes.title} align="left" gutterBottom>
                                ISC are the registered and preferred suppliers to The British Embassy, Qatar Petroleum, Qatar Petrochemical Company and Qatar Vinyl Company, British Airways, Chevron & Conoco Phillips among others. ISC also provides guarding and installation services to the Qatari Government, other diplomatic missions, the banking sector and construction industry in Qatar.
                            </Typography>
                            <Typography className={classes.title} align="left" gutterBottom>
                                ISC Group Gulf WLL are members of the British Security Industry Association (BSIA) and Security Association for the Maritime Industry (SAMI)
                            </Typography>
                            <Typography className={classes.title} align="left" gutterBottom>
                                ISC is not just another security company, but a team of professionals dedicated to achieving superior results for its clients.
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </CardContent >
        </Card >
    );
}