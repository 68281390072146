import React, { Fragment } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SnackbarContent from "@material-ui/core/SnackbarContent";

export default function SimpleSnackbar({ ...props }) {
    const { isOpen, isClose, isSuccess } = props

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={isOpen}
                autoHideDuration={6000}
                onClose={isClose}
            >
                <SnackbarContent
                    style={isSuccess ? { backgroundColor: '#228B22' } : { backgroundColor: '#E50000' }}
                    message={isSuccess ? "Successfully Submitted" : "Please Try Again"}
                    action={
                        <Fragment>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={isClose}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Fragment>
                    }
                />
            </Snackbar>
        </div>
    );
}