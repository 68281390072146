import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

function Copyright() {
    return (
        <Typography variant="body2" style={{ opacity: 0.5 }}>
            {'Copyright © '}
            1999 - {new Date().getFullYear()} {''}
            {'Doha, Qtr. All rights reserved'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    footer: {
        padding: theme.spacing(3, 2),
        marginTop: 'auto',
        color: '#fff',
        backgroundColor: theme.palette.primary.main
    },
    titleSpacing: {
        marginBottom: 20
    },
    textSpacing: {
        marginBottom: 10
    },
    textLink: {
        color: '#fff',
        '&:hover': {
            color: theme.palette.secondary.main
        }
    },
    titleFooter: {
        marginBottom: 20,
        paddingTop: 10,
        fontStyle: 'italic',
    },
    descriptionFooter: {
        marginBottom: 10,
        lineHeight: 2,
        width: '80%',
        fontStyle: 'italic',
        [theme.breakpoints.up('xs')]: {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            width: '80%',
        },
    }
}));

export default function StickyFooter() {
    const classes = useStyles();

    return (
        <footer className={classes.footer}>
            <Container maxWidth="lg">
                <Grid container spacing={2} style={{ paddingTop: 20, paddingBottom: 20 }}>
                    <Grid item xs={12} md={5}>
                        <Typography variant="h5" align="left" className={classes.titleFooter} gutterBottom>Your <b style={{ color: '#FFD400' }}>Security Solutions</b> Provider</Typography>
                        <Typography variant="body2" align="left" className={classes.descriptionFooter}>"ISC Group Gulf WLL has unrivalled reputation in Qatar, for providing cost effective high quality Security Solutions and fully trained bilingual security guards, technicians and engineers."</Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <div style={{ display: 'flex', marginBottom: 10 }}>
                            <img src={"https://res.cloudinary.com/alkautsars/image/upload/v1614155310/isc-gulf/Header/image_1_kf5ha2.png"} alt="Logo" height="50" width="50" />
                            <Typography variant="body1" align="left" className={classes.titleSpacing} style={{ marginTop: 10, marginLeft: 15 }} gutterBottom><b>ISC Group Gulf</b></Typography>
                        </div>
                        <Typography variant="body2" align="left" className={classes.textSpacing} gutterBottom>Second Floor, Building Number 289, Street 230</Typography>
                        <Typography variant="body2" align="left" className={classes.textSpacing} gutterBottom>C-Ring Road, New Salata</Typography>
                        <Typography variant="body2" align="left" className={classes.textSpacing} gutterBottom>PO Box 3602, Doha, State of Qatar</Typography>
                        <Typography variant="body2" align="left">Office Hours: 07.00 - 14.30 hrs Sunday - Thursday</Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography variant="body1" align="left" className={classes.titleSpacing} gutterBottom style={{ paddingTop: 10 }}><b>Contact</b></Typography>
                        <Typography variant="body2" align="left" className={classes.textSpacing} gutterBottom style={{ paddingTop: 10 }}>Tel: +974 44432751 / 44413717</Typography>
                        <Typography variant="body2" align="left" className={classes.textSpacing} gutterBottom>Fax: +974 44413093</Typography>
                        <Typography variant="body2" align="left">Email: info@iscgulf.com</Typography>
                    </Grid>
                    <Grid item xs={12} md={12} style={{ paddingTop: 40 }}>
                        <Copyright />
                    </Grid>
                </Grid>
            </Container>
        </footer>
    );
}