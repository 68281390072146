
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ProductCard from '../../components/card/Product';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link } from "react-router-dom";
import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles((theme) => ({
    cardGrid: {
        paddingTop: theme.spacing(15),
        paddingBottom: theme.spacing(8),
    },
    noDecoration: {
        textDecoration: 'none'
    }
}));

export default function Partners() {
    const classes = useStyles();
    // Mobile View
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.up('sm'));
    const [show, setShow] = useState(false);

    const showItem = () => {
        if (isMobileView ? window.scrollY >= 1080 : window.scrollY >= 1280) {
            setShow(true)
        } else {
            setShow(false)
        }
    }
    window.addEventListener('scroll', showItem);

    const productData = [
        {
            id: 1,
            name: "LenelS2 USA",
            img: "https://res.cloudinary.com/alkautsars/image/upload/v1617001842/isc-gulf/Product/Mask_Group_11_pymiud.png",
            description: "A global leader in advanced security systems.",
            transitionDelay: '0ms',
        },
        {
            id: 2,
            name: "March Network",
            img: "https://res.cloudinary.com/alkautsars/image/upload/v1617001053/isc-gulf/Product/Mask_Group-2_btgops.png",
            description: "A global leader in intelligent IP video solution.",
            transitionDelay: '300ms',
        },
        {
            id: 3,
            name: "Milestonesys",
            img: "https://res.cloudinary.com/alkautsars/image/upload/v1617001052/isc-gulf/Product/Mask_Group-6_giwao6.png",
            description: "A Global Leading VMS Provider.",
            transitionDelay: '600ms',
        }
    ]

    return (
        <Container className={classes.cardGrid} maxWidth="lg" id="partners" style={{ paddingBottom: 100 }}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={12}>
                    <Typography gutterBottom variant="h3" align="left" style={{ lineHeight: 1.3 }}>
                        Our <b style={{ color: '#FFD400' }}>__</b> <br /> Solutions and Partners
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Link to={"/partners"} className={classes.noDecoration}>
                        <Typography gutterBottom variant="body1" align="right">
                            View All
                        </Typography>
                    </Link>
                </Grid>
                <Grid item xs={12} md={12} style={{ paddingTop: 40 }}>
                    <Grid container alignItems="center" justify="center" spacing={4}>
                        {productData.map(x => (
                            <Zoom in={show} key={x.id} style={{ transitionDelay: x.transitionDelay }}>
                                <Grid item key={x.id} xs={12} md={4} style={{ textAlign: '-webkit-center' }}>
                                    <ProductCard name={x.name} img={x.img} description={x.description} />
                                </Grid>
                            </Zoom>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}
