import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 'auto',
        height: 240,
        boxShadow: '0 5px 10px rgba(154,160,185,0.05), 0 15px 40px rgba(166,173,201,0.2)',
        position: 'relative',
        textAlign: 'center',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            '& $hiddenText': {
                visibility: 'visible',
                position: 'absolute',
                top: '30%',
                left: 0,
                right: 0,
                paddingLeft: 10,
                paddingRight: 10
            },
            '& img': {
                visibility: 'hidden',
            }
        }
    },
    media: {
        height: '40%',
        width: '50%',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5)
    },
    content: {
        borderTop: '1px #e6e6e6 solid',
    },
    hiddenText: {
        visibility: 'hidden',
    },
}));

export default function ProductCard({ ...props }) {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <img
                className={classes.media}
                src={props.img}
                alt={props.name}
            />
            <CardContent className={classes.content}>
                <Typography variant="body1">
                    {props.name}
                </Typography>
                <Typography variant="body1" className={classes.hiddenText}>
                    <b>{props.description}</b>
                </Typography>
            </CardContent>
        </Card>
    );
}