import './App.css';
import { BrowserRouter as Router,Switch, Route } from "react-router-dom";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

// Pages
import Index from './pages/Index';
import LearnMore from './pages/LearnMore';
import Partners from './pages/Partners';

// Service
import Services from './pages/Services/Index';

// Security
import ServicesMannedGuarding from './pages/Services/Detail/MannedGuarding';
import ServicesConsultancy from './pages/Services/Detail/Consultancy';
import ServicesSecurity from './pages/Services/Detail/Security';

// 
import AppBar from './components/AppBar';
import Footer from './components/Footer';
import Contact from './components/Contact';

const theme = createMuiTheme({
  typography: {
    h2: {
      fontFamily: 'Montserrat',
      fontStyle: 'italic'
    },
    fontFamily: 'Manrope'
  },
  palette: {
    primary: {
      main: '#01056D'
    },
    secondary: {
      main: '#FFD400'
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router>
          <AppBar />
            <Switch>
              <Route exact path="/" component={Index}></Route>
              <Route path="/learnMore" component={LearnMore}></Route>
              <Route exact path="/services" component={Services}></Route>
              <Route path="/services/mannedGuarding" component={ServicesMannedGuarding}></Route>
              <Route path="/services/consultancy" component={ServicesConsultancy}></Route>
              <Route path="/services/securitySystems" component={ServicesSecurity}></Route>
              <Route path="/partners" component={Partners}></Route>
            </Switch>
          <Footer />
          <Contact />
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
