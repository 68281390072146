
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AboutCard from '../../components/card/About';
import { Link } from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Zoom from '@material-ui/core/Zoom';

// Icon
import ShieldIcon from '../../assets/img/aboutUs/shield.png';
import VisionIcon from '../../assets/img/aboutUs/vision.png';
import CoreIcon from '../../assets/img/aboutUs/core.png';
import SecurityIcon from '../../assets/img/aboutUs/security.png';
import ElectronicIcon from '../../assets/img/aboutUs/electronic.png';
import CoveredIcon from '../../assets/img/aboutUs/covered.png';
import MannedGuardingIcon from '../../assets/img/aboutUs/mannedGuarding.png';
import IntegratedIcon from '../../assets/img/aboutUs/integratedSystems.png';
import InfraIcon from '../../assets/img/aboutUs/database.png';
import BuildingIcon from '../../assets/img/aboutUs/end.png';
import ObjectiveIcon from '../../assets/img/aboutUs/objective.png';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#F7F7F7'
    },
    cardGrid: {
        [theme.breakpoints.up('xs')]: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(8),
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(15),
            paddingBottom: theme.spacing(8),
        },
    },
    noDecoration: {
        textDecoration: 'none'
    },
    padding40: {
        paddingTop: 40
    },
    padding60: {
        paddingTop: 60
    },
    description: {
        lineHeight: 1.8,
        opacity: 0.6
    },
}));

export default function AboutUs() {
    const classes = useStyles();
    // If trigger MobileView
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.up('sm'));

    const aboutData = [
        {
            id: 1,
            isBig: false,
            title: "Vision",
            img: VisionIcon,
            isMission: false,
            description: 'Our vision is to be a market leader, to define parameters and to shape the future of ICT Estate, inline with our mission. To protect the investment and enhance the experience of our clients.',
            transitionDelay: '300ms',
        },
        {
            id: 2,
            isBig: false,
            title: "Core Values",
            img: CoreIcon,
            isMission: false,
            description: 'As a company and individual, we value integrity, equality service, responsibility, accuracy, respect, dedication, improvement, credibility, quality and professionalism. We will continue to provide an extensive and high quality service for our customer.',
            transitionDelay: '600ms',
        },
        {
            id: 3,
            isBig: false,
            title: "Security Systems",
            img: SecurityIcon,
            isMission: false,
            description: 'ISC provides best-is-class security systems for medium and large companies, focused on prevention, detection, alarming and post-event analysis. We work closely with the end-users of these solutions throughout the whole implementation process and beyond.',
            transitionDelay: '900ms',
        },
        {
            id: 4,
            isBig: false,
            title: "Electronic Security",
            img: ElectronicIcon,
            isMission: false,
            description: 'With over 50.000 CCTV cameras installed, over 65.000 access controlled doors and over 2000 completed projects, we are the leaders in the business and we hold a unique expertise in the field',
            transitionDelay: '1200ms',
        },
        {
            id: 5,
            isBig: false,
            title: "Covered",
            img: CoveredIcon,
            isMission: false,
            description: 'If our clients are the victims of un-professional companies, ISC can handle highly critical and fragile situations, resolving in most of the cases the systems functionality and achieve maximum client operability.',
            transitionDelay: '1500ms',
        },
        {
            id: 6,
            isBig: false,
            title: "Manned Guarding",
            img: MannedGuardingIcon,
            isMission: false,
            description: 'If your concerns are perimeter protection, event crowd control, staff & public control access, ISC has a solid range of services and products. Our Guards & Protection staff is carefully selected, trained and supervised so no gaps are possible in our client designated protected areas.',
            transitionDelay: '1800ms',
        },
        {
            id: 7,
            isBig: false,
            title: "Integrated Systems",
            img: IntegratedIcon,
            isMission: false,
            description: 'We promote the integration of our systems with many 3rd party applications, being one of the first 100% Qatari owned security companies that implemented such systems. By this, we are able to successfully integrate complex platforms which cover all the functions and facilities needed by an integrated security system.',
            transitionDelay: '2100ms',
        },
        {
            id: 9,
            isBig: false,
            title: "Infrastructure Technologies",
            img: InfraIcon,
            isMission: false,
            description: 'We perform the equipping of custom cameras and of monitoring dispatchers for the optimal management of security systems. Networking, passive and active, uninterrupted power supply systems, monitoring and reporting status solutions.',
            transitionDelay: '2400ms',
        },
        {
            id: 10,
            isBig: false,
            title: "End-to-End Services",
            img: BuildingIcon,
            isMission: false,
            description: 'From consulting and risk analysis, through design and implementation, all the way to Qatar MOI project approvals, ISC is more than capable (and qualified) to handle all the professional services annexed to the systems implementations process.',
            transitionDelay: '2700ms',
        },
        {
            id: 11,
            isBig: false,
            title: "Objective & Mission",
            img: ObjectiveIcon,
            isMission: true,
            description: `Having gathered together the people, technology and systems, our commitment is to deliver these services to the total satisfaction of our clients.`,
            description2: `To provide a quality and extensive Security Solutions and service, tailored to suit our clients’ needs, maintaining our advance competitive edge.`,
            transitionDelay: '3000ms',
        },
    ]

    return (
        <div className={classes.root}>
            <Container className={classes.cardGrid} maxWidth="lg" id="aboutUs">
                <Grid container spacing={4}>
                    <Grid item xs={12} md={12} className={classes.padding40}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={8}>
                                <AboutCard isBig={true} title={"About Us"} img={ShieldIcon} description={"Since its establishment in 1999 - ISC Group Gulf – has made tremendous strides in improving the level and quality of service to its customers, building on its unrivaled success in security solutions and services, persistently being delivered to its high-end clients in critical sites across a multitude of business sectors in Military, Government, diplomatic, Oil & Gaz, private sector and banking entities in Qatar."} />
                            </Grid>
                            {aboutData.map((x, i) => (
                                <Zoom in={true} key={x.id} style={{ transitionDelay: x.transitionDelay }}>
                                    <Grid item xs={12} md={4}>
                                        <AboutCard isBig={x.isBig} title={x.title} img={x.img} description={x.description} isMission={x.isMission} description2={x.description2} />
                                    </Grid>
                                </Zoom>
                            ))}
                        </Grid>
                        <Grid item xs={12} md={12} className={classes.padding60}>
                            <Typography variant={isMobileView ? "body1" : "body2"} align="center" className={classes.description}>
                                We have structured our company to the best understand and supply to the services our clients want. <br />
                                Having gathered together the people, technology and systems,
                                <br /> our commitment is to deliver these services to the total satisfaction of our clients.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} className={classes.padding40}>
                            <Link to={"/learnMore"} className={classes.noDecoration}>
                                <Button color="primary">
                                    <b style={{ fontSize: 14, opacity: 0.6, letterSpacing: 0.5 }}>Learn More</b>
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
