import React, { Fragment, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Product from '../components/card/Product';
import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles((theme) => ({
    heroContent: {
        padding: theme.spacing(10, 0, 15),
        minHeight: '70vh',
        backgroundImage: `url(${"https://res.cloudinary.com/alkautsars/image/upload/v1614154262/isc-gulf/Header/img_our_product_jmepvo.png"})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    titleWrapper: {
        paddingTop: theme.spacing(12),
        [theme.breakpoints.up('xs')]: {
            paddingTop: theme.spacing(12),
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(12),
        },
        [theme.breakpoints.up('xl')]: {
            paddingTop: theme.spacing(23),
        },
    },
    cardGrid: {
        marginTop: -350,
        paddingBottom: theme.spacing(8),
        [theme.breakpoints.up('xs')]: {
            paddingTop: theme.spacing(18),
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(23)
        },
        [theme.breakpoints.up('xl')]: {
            paddingTop: theme.spacing(23)
        },
    },
    noDecoration: {
        textDecoration: 'none'
    }
}));


export default function LearnMore() {
    const classes = useStyles();

    useEffect(() => { window.scrollTo(0, 0); }, [])

    const productData = [
        {
            id: 1,
            name: "LenelS2 USA",
            img: "https://res.cloudinary.com/alkautsars/image/upload/v1617001842/isc-gulf/Product/Mask_Group_11_pymiud.png",
            description: "A global leader in advanced security systems.",
            transitionDelay: '0ms',
        },
        {
            id: 2,
            name: "March Network",
            img: "https://res.cloudinary.com/alkautsars/image/upload/v1617001053/isc-gulf/Product/Mask_Group-2_btgops.png",
            description: "A global leader in intelligent IP video solution.",
            transitionDelay: '300ms',
        },
        {
            id: 3,
            name: "Milestonesys",
            img: "https://res.cloudinary.com/alkautsars/image/upload/v1617001052/isc-gulf/Product/Mask_Group-6_giwao6.png",
            description: "A Global Leading VMS Provider.",
            transitionDelay: '600ms',
        },
        {
            id: 4,
            name: "Controlsoft",
            img: "https://res.cloudinary.com/alkautsars/image/upload/v1617001052/isc-gulf/Product/Mask_Group-3_iebtnt.png",
            description: " Development, manufacture and distribution of Access Control Solutions.",
            transitionDelay: '900ms',
        },
        {
            id: 5,
            name: "Axis",
            img: "https://res.cloudinary.com/alkautsars/image/upload/v1617001053/isc-gulf/Product/Mask_Group_vk7rzc.png",
            description: "Creating network solutions to improve security.",
            transitionDelay: '1200ms',
        },
        {
            id: 6,
            name: "HID",
            img: "https://res.cloudinary.com/alkautsars/image/upload/v1617001053/isc-gulf/Product/Mask_Group-9_eltcrh.png",
            description: "Provide securely access physical and digital places.",
            transitionDelay: '1500ms',
        },
        {
            id: 7,
            name: "HIKVISION",
            img: "https://res.cloudinary.com/alkautsars/image/upload/v1617001052/isc-gulf/Product/Mask_Group-7_er9iia.png",
            description: "World leading IoT solution provider.",
            transitionDelay: '1800ms',
        },
        {
            id: 8,
            name: "Gianni Taiwan",
            img: "https://res.cloudinary.com/alkautsars/image/upload/v1617001052/isc-gulf/Product/Mask_Group-8_cg0ppj.png",
            description: "The world's best quality access control products.",
            transitionDelay: '2100ms',
        },
        {
            id: 9,
            name: "Sprint Hong Kong",
            img: "https://res.cloudinary.com/alkautsars/image/upload/v1617001053/isc-gulf/Product/Mask_Group-4_djvewq.png",
            description: "Most reliable door security hardware.",
            transitionDelay: '2400ms',
        },
        {
            id: 10,
            name: "Heras USA",
            img: "https://res.cloudinary.com/alkautsars/image/upload/v1617001053/isc-gulf/Product/Mask_Group-10_olteei.png",
            description: "Permanent and mobile perimeter protection solutions.",
            transitionDelay: '2700ms',
        },
        {
            id: 11,
            name: "Gunnebo Italy",
            img: "https://res.cloudinary.com/alkautsars/image/upload/v1617001053/isc-gulf/Product/Mask_Group-5_xcavuc.png",
            description: "Provide security products, services and software.",
            transitionDelay: '3000ms',
        },
        {
            id: 12,
            name: "Boonedam Netherland's",
            img: "https://res.cloudinary.com/alkautsars/image/upload/v1617001053/isc-gulf/Product/Mask_Group-1_qesz4i.png",
            description: "Manufacturer Of Revolving Doors And Security Barriers.",
            transitionDelay: '3300ms',
        },
    ]

    return (
        <Fragment>
            <CssBaseline />
            <main>
                <div className={classes.heroContent}>
                    <Container maxWidth="lg" className={classes.titleWrapper}>
                        <Typography variant="h3" align="center" gutterBottom style={{ color: '#fff' }}>
                            <b>Our Solutions and Partners</b>
                        </Typography>
                    </Container>
                </div>
                <Container className={classes.cardGrid} maxWidth="md">
                    <Grid container spacing={4}>
                        {productData.map(x => (
                            <Zoom in={true} key={x.id} style={{ transitionDelay: x.transitionDelay }}>
                                <Grid item xs={12} md={4}>
                                    <a href={x.link} rel="noreferrer" target="_blank" className={classes.noDecoration}>
                                        <Product name={x.name} img={x.img} description={x.description} />
                                    </a>
                                </Grid>
                            </Zoom>
                        ))}
                    </Grid>
                </Container>
            </main>
        </Fragment>
    );
}