import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
    root: {
        // maxHeight: '100vh',
        boxShadow: 'unset',
        borderRadius: 18
    },
    text: {
        lineHeight: 2.3,
        [theme.breakpoints.up('xs')]: {
            fontSize: 14,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 16,
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: 16,
        },
    },
}));

export default function MannedGuard() {
    const classes = useStyles();

    useEffect(() => { window.scrollTo(0, 0); }, [])

    return (
        <Card className={classes.root}>
            <CardContent>
                <Container maxWidth="md">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} style={{ paddingTop: 40 }}>
                            <Typography variant="h6" align="left" color="primary" gutterBottom>
                                <b>Description</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} style={{ paddingTop: 10 }}>
                            <Typography className={classes.text} align="left" gutterBottom style={{ marginBottom: 10 }}>
                                As a company that prides itself on the <b>quality</b> and <b>breadth of experience</b> that its employees posses, we are able to offer a <b>comprehensive consultancy service</b>. This service can be tailored to meet each client's requirements, providing solutions in all aspects of security-integrated systems for Government, commercial and the private sectors.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} style={{ paddingTop: 40 }}>
                            <Typography variant="h6" align="left" color="primary" gutterBottom>
                                <b>Project Management</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} style={{ paddingTop: 10 }}>
                            <Typography className={classes.text} align="left" gutterBottom style={{ marginBottom: 10 }}>
                                All our Project Managers have a <b>high degree</b> of technical knowledge and are experienced in the latest methods and practices required to <b>successfully</b> complete works programs.
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </CardContent >
        </Card >
    );
}