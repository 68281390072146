import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
    root: {
        // maxHeight: '100vh',
        boxShadow: 'unset',
        borderRadius: 18
    },
    text: {
        lineHeight: 2.3,
        [theme.breakpoints.up('xs')]: {
            fontSize: 14,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 16,
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: 16,
        },
    },
}));

export default function MannedGuard() {
    const classes = useStyles();

    useEffect(() => { window.scrollTo(0, 0); }, [])

    return (
        <Card className={classes.root}>
            <CardContent>
                <Container maxWidth="md">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} style={{ paddingTop: 40 }}>
                            <Typography variant="h6" align="left" color="primary" gutterBottom>
                                <b>Description</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} style={{ paddingTop: 10 }}>
                            <Typography className={classes.text} align="left" gutterBottom style={{ marginBottom: 10 }}>
                                Our guards are usually former <b>police or military</b> personnel and receive training in all the latest industrial security, and safety techniques. Added to this is ongoing refresher training provided from our office in Doha. This ensures that we provide <b>only fully trained and bi-lingual guards</b> to our clients in the State of Qatar.
                            </Typography>
                            <Typography className={classes.text} align="left" gutterBottom style={{ marginBottom: 10 }}>
                                We provide a <b>24 hour</b> mobile patrol service, which checks all locations to ensure that all staff are alert and fully supported in the event of <b>any incident</b>. Our Operations Manager also conducts random location checks and he is available at <b>short notice</b>, on a 24 hour basis, with response guards if required.
                            </Typography>
                            <Typography className={classes.text} align="left" gutterBottom style={{ marginBottom: 10 }}>
                                Our guards are also <b>trained</b> on use and operation of CCTV, Electronic Access Control & Intruder/Fire Alarm systems by our installation and systems managers.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} style={{ paddingTop: 10, paddingBottom: 40 }}>
                            <Typography className={classes.text} align="left" gutterBottom style={{ marginBottom: 10 }}>
                                During special holiday periods, ISC have provided uniformed guards to Ministry of Interior for protection of Corniche electrical & display equipment, crowd control at open air musical events and duties at Qatar Museum for special exhibitions.
                            </Typography>
                            <Typography className={classes.text} align="left" style={{ fontStyle: 'italic' }}>
                                <b>Please note:</b> ISC have signed confidentiality / secrecy agreements with all clients and may not disclose details of guard numbers and other contract details without permission from the client.
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </CardContent >
        </Card >
    );
}