import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DialogContact from './contact/Dialog';
import SupportIcon from '../assets/img/support.svg'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        border: '3px solid #FFD400',
        position: 'fixed',
        right: '2%',
        bottom: '2%',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    icon: {
        color: '#FFD400',
    }
}));

export default function IconButtons() {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true)
    }
    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    return (
        <div className={classes.root}>
            <DialogContact isOpen={openDialog} isClose={handleCloseDialog} />
            <IconButton aria-label="delete" onClick={handleOpenDialog}>
                <img src={SupportIcon} alt="ISC Gulf" className={classes.icon} height="50" width="50" />
            </IconButton>
        </div>
    );
}